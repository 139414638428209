<template>
  <section class="project--detail-nearby">
    <h2 class="title--text">
      {{ $t('addProject.facility') }}
    </h2>
    <div class="column--wrapper">
      <div
        class="column--content"
        v-for="(facility, index) in facilities"
        :key="'facility-' + index"
      >
        <v-icon>{{ facility.icon }}</v-icon>
        <div class="column--content-right ml-2">
          <div class="column--desc">
            <span>{{ facility.name }}</span>
            <span style="min-width: 70px; text-align: right"
              >{{ facility.amount }} {{ facility.unit }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'project-detail-detail',
  props: ['facilities'],
  methods: {},
};
</script>
