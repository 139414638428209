<template>
  <section class="project--detail-similar">
    <h2 class="title--text">
      {{ $t('general.unitTypes') }}
    </h2>
    <div v-if="unitTypes && unitTypes.length > 0">
      <slick-unit-types :contents="unitTypes" :slidesPerRow="3" />
    </div>
  </section>
</template>

<script>
import SlickUnitTypes from '@/components/project-detail/partials/slick-unit-types';
export default {
  props: ['unitTypes'],
  components: {
    SlickUnitTypes,
  },
};
</script>
