<template>
  <modal
    :name="modalName"
    adaptive
    :width="500"
    @before-open="beforeOpen"
    height="auto"
    class="modal--contact-us"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ title }}
      </div>
      <section class="modal--container">
        <div class="title--text hide--in-mobile" style="margin-top: 60px">
          {{ title }}
        </div>
        <div class="wrapper--main">
          <!--          <div class="px-2 pb-2">-->
          <!--            {{ $t('contact.modal.description') }}-->
          <!--          </div>-->
          <form @submit.prevent="submit">
            <div class="add-space">
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('name') }"
              >
                <input
                  class="basic--input"
                  id="name"
                  type="text"
                  v-model="name"
                  placeholder="Nama"
                />
                <div class="val-error mt-2" v-if="validation.hasError('name')">
                  {{ validation.firstError('name') }}
                </div>
              </div>
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('email') }"
              >
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  class="basic--input"
                  placeholder="Email"
                />
                <div class="val-error mt-2" v-if="validation.hasError('email')">
                  {{ validation.firstError('email') }}
                </div>
              </div>
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('phone') }"
              >
                <client-only>
                  <vue-tel-input
                    id="phone"
                    :value="phone ? phone : ''"
                    @input="phoneInput"
                    :validCharactersOnly="true"
                    defaultCountry="id"
                    :inputOptions="inputOptions"
                  />
                </client-only>
                <div class="val-error mt-2" v-if="validation.hasError('phone')">
                  {{ validation.firstError('phone') }}
                </div>
              </div>
            </div>
            <div
              class="row desc-card justify-content-center"
              style="margin-top: 20px; margin-bottom: 60px"
            >
              <div class="col-9">
                <button class="btn btn-primary btn-block" type="submit">
                  {{ $t('contact.modal.submit') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import Cookies from 'js-cookie';
import { mapGetters, mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {
    VueTelInput,
  },
  data: () => ({
    modalName: 'modal--contact-us',
    loading: false,
    title: '',
    listingUuid: null,
  }),
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.global.loggedIn,
      user: (state) => state.global.user,
    }),
    ...mapGetters({
      currentContactData: 'v2/contact/contactData',
    }),
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.phone'),
      };
    },
    name: {
      get() {
        return this.$store.state.v2.contact.name;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_NAME', this.cleanInput(val));
      },
    },
    email: {
      get() {
        return this.$store.state.v2.contact.email;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_EMAIL', this.cleanInput(val));
      },
    },
    phoneCountryCode: {
      get() {
        return this.$store.state.v2.contact.phoneCountryCode;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_PHONE_COUNTRY_CODE', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.v2.contact.phone;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_PHONE', val);
      },
    },
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.$store.commit('v2/contact/SET_PHONE', phone);
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.$store.commit('v2/contact/RESTORE_DEFAULT_VALUE');

      this.title = this.$t('contact.modal.title');
      this.listingUuid = event.params.listingUuid;

      if (!this.isLoggedIn) {
        const cookieStr = document.cookie;
        const cookies = require('cookie').parse(cookieStr || '') || {};
        if (cookies.contactData) {
          let cookieContactData = JSON.parse(cookies.contactData);
          console.log('CONTACT DATA: ', cookieContactData);
          this.name = cookieContactData.name;
          this.email = cookieContactData.email;
          this.phone = cookieContactData.phone;
        }
      } else {
        console.log('CONTACT DATA: ', this.user);
        this.name = this.user.name;
        this.email = this.user.email;
        this.phone = this.user.phone_number;
      }
    },
    async submit() {
      const isValid = await this.$validate();
      if (isValid) {
        let usedRequesterUuid = null;
        const cookieStr = document.cookie;
        const cookies = require('cookie').parse(cookieStr || '') || {};
        if (cookies.requesterUuid) {
          usedRequesterUuid = cookies.requesterUuid;
        }

        console.log('KIRIM INFO BUAT CONTACT US: ', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          listingUuid: this.listingUuid,
          requesterUuid: usedRequesterUuid,
        });

        try {
          let data = await this.$store.dispatch('v2/contact/sendContactRequest', {
            listingUuid: this.listingUuid,
            requesterUuid: usedRequesterUuid, // INI JANGAN NULL KALAU PRODUCTION
          });
          console.log('HASIL REQUEST CONTACT: ', data);
          if (data.type === 'success') {
            if (!this.isLoggedIn) {
              let cookieContactData = null;
              if (cookies.contactData) {
                cookieContactData = JSON.parse(cookies.contactData);
              }
              console.log('CURRENT CONTACT DATA: ', this.currentContactData);
              console.log('COOKIE CONTACT DATA: ', cookieContactData);
              if (
                cookieContactData === null ||
                this.currentContactData.name !== cookieContactData.name ||
                this.currentContactData.phone !== cookieContactData.phone ||
                this.currentContactData.email !== cookieContactData.email
              ) {
                console.log('SIMPAN CONTACT DATA: ', this.currentContactData);
                Cookies.set(
                  'contactData',
                  {
                    name: this.currentContactData.name,
                    phone: this.currentContactData.phone,
                    email: this.currentContactData.email,
                  },
                  { expires: 30 },
                );
              }
            }
            console.log('ISI DATA HASIL ENQUIRY: ', data.data);
            if (data.data && data.data.requester_uuid) {
              const uuid = data.data.requester_uuid;
              if (usedRequesterUuid !== uuid) {
                console.log('SIMPAN REQUEST UUID: ', uuid);
                Cookies.set('requesterUuid', uuid, { expires: 30 });
              }
            }
            this.close();
            await this.$swal(
              this.$t('general.success'),
              this.$t('contact.modal.successTitle'),
              'success',
            );
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.log('ERROR REQUEST CONTACT: ', e);
        }
      }
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.name.required'));
    },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
};
</script>
